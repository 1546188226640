import TC from "./table/Table.module.css";

import type { ReactNode } from "react";
import { getResourceIconClass, getResourceTypeName, type ResourceType } from "../modules/api/resourceTypes.tsx";
import { cn } from "../utils/classNames.ts";
import { MaskIcon } from "./icon/MaskIcon.tsx";

export function ResourceWithIcon({ type, title }: { type: ResourceType; title?: ReactNode }) {
    return (
        <DetailWithIcon
            title={title ? title : getResourceTypeName(type)}
            icon={<MaskIcon className={cn("size-0.75rem text-primary", getResourceIconClass(type))} />}
        />
    );
}

export function DetailWithIcon({ title, icon }: { title: ReactNode; icon: ReactNode }) {
    return (
        <div className="flex items-center gap-0.375em max-w-12rem">
            <span className={TC.Icon}>{icon}</span> {title}
        </div>
    );
}
